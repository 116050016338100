import { useEffect, useRef, useState } from "react";

function formatTimer(timer: number) {
	return new Intl.DateTimeFormat("ru-RU", {
		minute: "2-digit",
		second: "2-digit",
	}).format(new Date(timer));
}

export function useTimer(
	time: number,
	options?: Partial<{ onTimeEnd: () => void }>,
) {
	const intervalRef = useRef<NodeJS.Timer | undefined>(undefined);
	const [timer, setSeconds] = useState<number>(time || -1);

	// Timer
	useEffect(() => {
		if (timer !== -1) {
			intervalRef.current = setInterval(() => {
				if (timer > 0) {
					setSeconds(timer - 1);
				} else {
					clearInterval(intervalRef.current);
					options?.onTimeEnd?.();
				}
			}, 1000);

			return () => {
				clearInterval(intervalRef.current);
			};
		}
	}, [timer]);

	return formatTimer(timer * 1000);
}

// import { openDB, IDBPDatabase } from "idb";

import { API_ENDPOINT } from "./constants";
import type {
	AlfaAuthResult,
	APIResponse,
	AuthResult,
	CreateOrderResult,
	FailPayResult,
	GetActionResult,
	GetAllActionsByCityResult,
	GetAllActionsResult,
	GetAllCitiesResult,
	GetCartResult,
	GetFiltersResult,
	GetOrdersResult,
	GetTicketsByOrderResult,
	NewOrder,
	ReserveResult,
	SetCityResult,
	SuccessPayResult,
	UnreserveResult,
	GetSeatList,
} from "./types";
import { APIError } from "./error";

class APIService {
	#api_key: string | null = localStorage.getItem("api_key") || null;

	constructor() {
		const searchParams = new URLSearchParams(location.search);

		const api_key = searchParams.get("api_key");

		if (api_key) {
			this.#api_key = api_key;
			localStorage.setItem("api_key", api_key);
		}
	}

	async getFilters() {
		return this.#fetch<GetFiltersResult>("/get_filters", {
			method: "POST",
			body: JSON.stringify({
				api_key: this.#api_key,
			}),
		});
	}

	async getSeatList(args: { eid: string }) {
		return this.#fetch<GetSeatList>("/get_seat_list", {
			method: "POST",
			body: JSON.stringify({
				api_key: this.#api_key,
				eid: args.eid,
			}),
		});
	}

	async getAllCities() {
		return this.#fetch<GetAllCitiesResult>("/get_all_cities", {
			method: "POST",
			body: JSON.stringify({
				api_key: this.#api_key,
			}),
		});
	}

	async getAllActions() {
		return this.#fetch<GetAllActionsResult>("/get_all_actions", {
			method: "POST",
			body: JSON.stringify({
				api_key: this.#api_key,
			}),
		});
	}

	async getAllActionsByCity(args: { cid: number | string }) {
		return this.#fetch<GetAllActionsByCityResult>("/get_all_actions_by_city", {
			method: "POST",
			body: JSON.stringify({
				api_key: this.#api_key,
				cid: args.cid,
			}),
		});
	}

	async getAction(args: { actionId: string; venueId: string; cityId: string }) {
		return this.#fetch<GetActionResult>("/get_action_ext", {
			method: "POST",
			body: JSON.stringify({
				api_key: this.#api_key,
				aid: args.actionId,
				vid: args.venueId,
				cid: args.cityId,
			}),
		});
	}

	async setCity(args: { cityId: string | number }) {
		if (!this.#api_key) {
			return null;
		}

		return await this.#fetch<SetCityResult>("/set_city", {
			method: "POST",
			body: JSON.stringify({ api_key: this.#api_key, cid: args.cityId }),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}

	async getSchema(args: { eid: string | number }) {
		return await this.#fetch<string>("/get_schema", {
			method: "POST",
			body: JSON.stringify({ api_key: this.#api_key, eid: args.eid }),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}

	async reserve(args: {
		eid: number;
		categories?: Array<{ cid: number; tid?: number; qty: number }>;
		seats?: Array<{ sid: number; tid?: number }>;
	}) {
		return await this.#fetch<ReserveResult>("/reserve", {
			method: "POST",
			body: JSON.stringify({
				eid: args.eid,
				api_key: this.#api_key,
				categories: args.categories,
				seats: args.seats,
			}),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}

	async unreserve(args: { seats: Array<{ sid: number }> }) {
		return await this.#fetch<UnreserveResult>("/unreserve", {
			method: "POST",
			body: JSON.stringify({ api_key: this.#api_key, seats: args.seats }),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}

	async unreserveAll(args?: { eid?: string }) {
		return await this.#fetch<UnreserveResult>("/unreserve_all", {
			method: "POST",
			body: JSON.stringify({ api_key: this.#api_key, eid: args?.eid }),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}

	async auth(args: { email: string }) {
		return await this.#fetch<AuthResult>("/auth", {
			method: "POST",
			body: JSON.stringify({ api_key: this.#api_key, email: args.email }),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}

	async getCart() {
		return await this.#fetch<GetCartResult>("/get_cart", {
			method: "POST",
			body: JSON.stringify({ api_key: this.#api_key }),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}

	async createOrder(args: {
		sum: string;
		currency: string;
		phone?: string;
		name?: string;
	}) {
		return await this.#fetch<CreateOrderResult>("/create_order", {
			method: "POST",
			body: JSON.stringify({
				api_key: this.#api_key,
				phone: args.phone,
				name: args.name,
				sum: args.sum,
				currency: args.currency,
			}),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}

	async getOrders() {
		return await this.#fetch<GetOrdersResult>("/get_orders_ext", {
			method: "POST",
			body: JSON.stringify({ api_key: this.#api_key }),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}

	async getTicketsByOrder(args: { oid: number | string }) {
		return await this.#fetch<GetTicketsByOrderResult>("/get_tickets_by_order", {
			method: "POST",
			body: JSON.stringify({ api_key: this.#api_key, oid: args.oid }),
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}

	async alfaAuth(args: { guid: string }) {
		return await this.#fetch<AlfaAuthResult>("/alfa_auth", {
			method: "POST",
			body: JSON.stringify({ guid: args.guid }),
		});
	}

	async successPay(args: { uid: string; oid: number; bid: string }) {
		return await this.#fetch<SuccessPayResult>("/success_pay", {
			method: "POST",
			body: JSON.stringify({
				uid: args.uid,
				oid: args.oid,
				bid: args.bid,
			}),
		});
	}

	async failPay(args: { uid: string; oid: number; bid?: string }) {
		return await this.#fetch<FailPayResult>("/fail_pay", {
			method: "POST",
			body: JSON.stringify({
				uid: args.uid,
				oid: String(args.oid),
				bid: args.bid,
			}),
		});
	}

	async deleteOrder(args: { oid: number }) {
		return await this.#fetch<string>("/delete_order", {
			method: "POST",
			body: JSON.stringify({ api_key: this.#api_key, oid: args.oid }),
		});
	}

	refund(args: {
		oid: string;
		name?: string;
		email?: string;
		phone?: string;
		reason?: string;
	}) {
		return this.#fetch<"Ok">("/refund_order", {
			method: "POST",
			body: JSON.stringify({
				api_key: this.#api_key,
				oid: args.oid,
				name: args.name,
				email: args.email,
				phone: args.phone,
				reason: args.reason,
			}),
		});
	}

	async getNewOrders() {
		return this.#fetch<Record<string, NewOrder>>("/get_new_orders", {
			method: "POST",
			body: JSON.stringify({
				api_key: this.#api_key,
			}),
		});
	}

	async getRefundInfo(args: {
		oid: string;
		tids: string[];
		reason: string;
	}) {
		return this.#fetch<{
			timeLeft: number;
			refundingPercent: number;
			sum: number;
			refundingSum: number;
			user: {
				name: string;
				mail: string;
				phone: string;
			};
		}>("/get_refund_info", {
			method: "POST",
			body: JSON.stringify({
				api_key: this.#api_key,
				...args,
			}),
		});
	}

	getTicketPDFUrl(args: { oid: number; tid: number }) {
		return `${API_ENDPOINT}/tickets/pdf?oid=${args.oid}&tid=${args.tid}&api_key=${this.#api_key}`;
	}

	async #fetch<T>(path: string, init?: RequestInit): Promise<T> {
		const url = `${API_ENDPOINT}/json${path}`;

		return fetch(url, init)
			.then((res) => res.json() as Promise<APIResponse<T>>)
			.then((data) => {
				// Update API key
				if (data.api_key) {
					this.#setToken(data.api_key);
				}

				if (data.type !== "success") {
					throw new APIError({
						type: data.type,
						code: String(data.error) || "unknown_error",
						message: data.message || "Произошла ошибка",
						advice: data.advice,
						system: data.system,
						error: data.error,
					});
				}

				return data.decode;
			});
	}

	#setToken(token: string) {
		this.#api_key = token;
		localStorage.setItem("api_key", token);
	}
}

export const apiService = new APIService();

import { ENV } from "./constants";

const appId = `kassir_ru_${ENV === "prod" ? "int" : "dev"}`;
const platform = ENV === "prod" ? "mob" : "web";
const endpoint =
	ENV === "prod"
		? "metrics.alfabank.ru/metrica/retail"
		: "testjmb.alfabank.ru/metrica/retail";

// @ts-ignore
sp("newTracker", "ab", endpoint, {
	appId,
	platform,
	discoverRootDomain: true,
	forceSecureTracker: true,
});

export function snowplow(...args: any[]) {
	// @ts-ignore
	sp(...args);
}

export function trackScreenView(
	eventLabel: string,
	pageTitle = document.title,
) {
	return snowplow(
		"trackStructEvent",
		"Partners promotions",
		"Screen View",
		eventLabel,
		null,
		null,
		[
			{
				schema: "iglu:com.alfabank/custom_dimension/jsonschema/1g-0-0",
				data: {
					"18": pageTitle,
				},
			},
		],
	);
}

export function trackEvent(
	label: string,
	action: string,
	dimensions: {
		title?: string;
		widget?: string;
		category?: string;
		action?: string;
		sort?: string;
		filterName?: string;
		filter?: string;
		symbol?: string;
	},
) {
	const { title = document.title } = dimensions;

	return snowplow(
		"trackStructEvent",
		"Partners promotions",
		action,
		label,
		null,
		null,
		[
			{
				schema: "iglu:com.alfabank/custom_dimension/jsonschema/1-0-0",
				data: {
					"18": title,
					"17": dimensions.widget,
					"9": dimensions.category,
					"1": dimensions.action,
					"14": dimensions.filterName,
					"13": dimensions.filter,
					"12": dimensions.sort,
					"3": dimensions.symbol,
				},
			},
		],
	);
}

const EMAIL_REGEX =
	/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export function validateEmail(value: string) {
	if (value === "") {
		return "Это поле обязательно";
	}
	if (!EMAIL_REGEX.test(value)) {
		return "Некорректный email";
	}
	return null;
}

const NAME_REGEX =
	/^[А-ЯЁ][а-яё]*([-][А-ЯЁ][а-яё]*)?\s[А-ЯЁ][а-яё]*(\s[А-ЯЁ][а-яё]*)?$/i;

export function validateName(value: string) {
	if (value === "") {
		return "Это поле обязательно";
	}
	if (!NAME_REGEX.test(value)) {
		return "Некорректное ФИО";
	}
	return null;
}

const TEL_REGEX = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

export function validatePhone(value: string) {
	if (value === "") {
		return "Это поле обязательно";
	}
	if (!TEL_REGEX.test(value)) {
		return "Некорректный номер";
	}
	return null;
}

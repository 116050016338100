import cx from "classnames";
import { Typography } from "@alfalab/core-components/typography";

import styles from "./Cashback.module.css";

type CashbackProps = {
	value: string;
	className?: string;
};

export const Cashback: React.FC<CashbackProps> = ({ value, className }) => {
	return (
		<div className={cx(styles["container"], className)}>
			<span className={styles["cashback-icon"]} />

			<Typography.Text
				view="secondary-medium"
				weight="medium"
				color="static-primary-dark"
			>
				Кэшбэк {value}
			</Typography.Text>
		</div>
	);
};

export const CashbackAB: React.FC<CashbackProps> = ({ value, className }) => {
	return (
		<div className={cx(styles["container"], className)}>
			<span className={styles["cashback-icon"]} />

			<Typography.Text
				view="secondary-medium"
				weight="medium"
				color="static-primary-dark"
			>
				Кэшбэк {value}
			</Typography.Text>
		</div>
	);
};

import { useState } from "react";

type ToggleCallback = (value?: boolean | ((value: boolean) => boolean)) => void;

export function useToggle(defaultValue = false): [boolean, ToggleCallback] {
	const [isToggle, setToggle] = useState(defaultValue);

	const toggle: ToggleCallback = (value) => {
		if (typeof value === "function") {
			setToggle(value(isToggle));
		} else {
			setToggle((prev) => (typeof value !== "undefined" ? value : !prev));
		}
	};

	return [isToggle, toggle];
}

import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { Input } from "@alfalab/core-components/input";
import { Badge } from "@alfalab/core-components/badge";
import { Typography } from "@alfalab/core-components/typography";
import { SuperEllipse } from "@alfalab/core-components/icon-view/super-ellipse";
import { IconButton } from "@alfalab/core-components/icon-button";
import { MagnifierMIcon } from "@alfalab/icons-glyph/MagnifierMIcon";
import { SlidersMIcon } from "@alfalab/icons-glyph/SlidersMIcon";
import { UserLineMIcon } from "@alfalab/icons-glyph/UserLineMIcon";
import cx from "classnames";

import { useActions } from "@/lib/actions/ActionsContext";
import { trackEvent } from "@/lib/metrica";
import { transliterate } from "@/lib/utils/transliterate";

import styles from "./Header.module.css";

type NavItemProps = {
	label: string;
	active?: boolean;
	onClick(): void;
};

const NavItem: React.FC<NavItemProps> = ({ label, active, onClick }) => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={cx(styles["tab"], active && styles["tab-active"])}
		>
			<Typography.Text view="primary-small" style={{ whiteSpace: "nowrap" }}>
				{label}
			</Typography.Text>
		</button>
	);
};

function formatSearchParams(search: string) {
	const sp = new URLSearchParams(search);
	sp.delete("item");

	return sp.toString();
}

export const Header: React.FC = () => {
	const navigate = useNavigate();
	const { menus, city, setMenu, filters, totalFiltersCount, newOrders } =
		useActions();

	const handleSearchClick = () => {
		trackEvent("MainGlobalSearch", "Click", {
			widget: "CityView",
			category: "Главный раздел",
			action: "Search click",
		});

		navigate("./search");
	};

	const handleTabClick = (
		menu: { menuId: number; menuName: string } | null,
	) => {
		trackEvent(
			`MainFilterButton${menu ? transliterate(menu.menuName) : "All"}`,
			"click",
			{
				widget: "Header",
				category: "Главный раздел",
				action: "Category click",
			},
		);

		setMenu(menu?.menuId || null);
	};

	return (
		<Fragment>
			<div className={styles["search-section"]}>
				<SuperEllipse
					size={40}
					backgroundColor="transparent"
					topAddons={
						<Badge view="count" height={16} content={newOrders.orders} />
					}
				>
					<IconButton
						onClick={() => {
							trackEvent("MainIconAccount", "click", {
								widget: "CityView",
								category: "Главный раздел",
								action: "Account button click",
							});

							navigate("/account");
						}}
						icon={UserLineMIcon}
						size="xxs"
						aria-label="Аккаунт"
					/>
				</SuperEllipse>

				<Input
					size="s"
					block
					placeholder={city ? `Поиск ${city.where}` : "Поиск"}
					leftAddons={
						<MagnifierMIcon color="var(--color-light-text-tertiary)" />
					}
					onFocus={handleSearchClick}
					readOnly
				/>

				<div className={styles["filter-buttons"]}>
					<SuperEllipse
						size={40}
						backgroundColor="transparent"
						topAddons={
							<Badge view="count" height={16} content={totalFiltersCount} />
						}
					>
						<IconButton
							onClick={() => {
								trackEvent("MainIconFilter", "click", {
									widget: "CityView",
									category: "Главный раздел",
									action: "Filters button click",
								});

								navigate({
									pathname: "./filters",
									search: formatSearchParams(location.search),
								});
							}}
							icon={SlidersMIcon}
							size="xxs"
							aria-label="Фильтры"
						/>
					</SuperEllipse>
				</div>
			</div>

			<div className={styles["menu-section"]}>
				<ScrollContainer className={styles["scroll-container"]}>
					<ul className={styles["menu"]}>
						<li
							data-menu-item-id="all"
							data-active={!filters.menu ? true : undefined}
						>
							<NavItem
								label="Все"
								active={!filters.menu}
								onClick={() => handleTabClick(null)}
							/>
						</li>

						{menus.map((menu) => (
							<li
								key={menu.menuId}
								data-menu-item-id={menu.menuId}
								data-active={filters.menu === menu.menuId ? true : undefined}
							>
								<NavItem
									label={menu.menuName}
									active={filters.menu === menu.menuId}
									onClick={() => handleTabClick(menu)}
								/>
							</li>
						))}
					</ul>
				</ScrollContainer>
			</div>
		</Fragment>
	);
};

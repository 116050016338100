import { Suspense, useEffect } from "react";
import {
	Outlet,
	Await,
	createBrowserRouter,
	defer,
	type LoaderFunction,
	RouterProvider,
	useAsyncError,
	useLoaderData,
	Navigate,
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import * as Sentry from "@sentry/react";
import qs from "query-string";

import { MainLayout } from "./components/layouts/main-layout/MainLayout";
import { PageLoader } from "./components/page-loader/PageLoader";
import { ErrorBoundary } from "./components/error/ErrorBoundary";
import { OnboardingView } from "./pages/onboarding/OnboardingView";
import { AuthView, authLoader } from "./pages/auth/AuthView";
import { CityView, cityViewLoader } from "./pages/city/CityView";
import { WithCart } from "./lib/cart/CartContext";
import { WithActions } from "./lib/actions/ActionsContext";
import { WithCities } from "./lib/city/CityContext";
import { WithApp } from "./lib/app";
import { apiService } from "./lib/api";
import { APIError } from "./lib/error";
import type { GetAllActionsByCityResult } from "./lib/types";

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://589737599c0f6c53e32c5195d1e62a0b@o4507049725919232.ingest.us.sentry.io/4507049736273920",
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],
		environment:
			process.env.PUBLIC_MODE === "staging" ? "staging" : "production",
		tracesSampleRate: 1.0,
	});
}

const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const rootLoader = () => {
	const citiesPromise = apiService.getAllCities();

	return defer({
		cities: citiesPromise,
	});
};

const RootWrapper: React.FC = () => {
	const data = useLoaderData() as {
		cities: ReturnType<typeof apiService.getAllCities>;
	};

	return (
		<Suspense fallback={<PageLoader />}>
			<Await resolve={data.cities} errorElement={<CityWrapperError />}>
				{(countries) => (
					<WithCities cities={countries[0].cities}>
						<WithCart>
							<MainLayout />
						</WithCart>
					</WithCities>
				)}
			</Await>
		</Suspense>
	);
};

type ActionsData = {
	actions: Promise<GetAllActionsByCityResult>;
};

const CityWrapperError: React.FC = () => {
	const error = useAsyncError() as Error;

	if (error instanceof APIError && error.type === "message") {
		return Navigate({ to: "/city", replace: true });
	}

	throw error;
};

const CityWrapper = () => {
	const data = useLoaderData() as ActionsData;

	return (
		<Suspense fallback={<PageLoader />}>
			<Await resolve={data.actions} errorElement={<CityWrapperError />}>
				{(actions) => (
					<WithActions data={actions}>
						<Outlet />
					</WithActions>
				)}
			</Await>
		</Suspense>
	);
};

const cityLoader: LoaderFunction = ({ params }) => {
	const cityId = params.cid;
	const actionsPromise = apiService
		.getAllActionsByCity({
			cid: String(cityId),
		})
		.catch(() => location.replace("/city"));

	return defer({
		actions: actionsPromise,
	});
};

const AppWrapper: React.FC = () => {
	return (
		<QueryParamProvider
			adapter={ReactRouter6Adapter}
			options={{
				searchStringToObject: qs.parse,
				objectToSearchString: qs.stringify,
			}}
		>
			<WithApp>
				<Outlet />
			</WithApp>
		</QueryParamProvider>
	);
};

const router = sentryCreateBrowserRouter([
	{
		element: <AppWrapper />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				children: [
					{
						path: "/alfa_recommends_24_29_05_24",
						lazy: () =>
							import("./pages/alfa_recommends_24_29_05_24/AlfaFriday"),
					},
					{
						path: "/alfa_recommends_24_29_06_24",
						lazy: () =>
							import("./pages/alfa_recommends_24_29_06_24/AlfaFriday"),
					},
					{
						path: "/",
						element: <OnboardingView pageId={1} />,
					},
					// Auth callback page
					{
						path: "/alfa-auth/:guid",
						element: <AuthView />,
						loader: authLoader,
					},
				],
			},
			{
				element: <RootWrapper />,
				loader: rootLoader,
				shouldRevalidate: () => false,
				children: [
					// Set city page
					{
						path: "/city",
						lazy: () => import("./pages/cities/CitiesView"),
					},
					// Account page
					{
						path: "/account",
						lazy: () => import("./pages/account/AccountView"),
					},
					{
						element: <CityWrapper />,
						loader: cityLoader,
						shouldRevalidate: () => false,
						children: [
							// Action pages
							{
								path: "/city/:cid",
								element: <CityView />,
								loader: cityViewLoader,
								// @ts-ignore
								shouldRevalidate: ({ currentParams, nextParams }) => {
									return currentParams.cid !== nextParams.cid;
								},
							},
							{
								path: "/city/:cid/date",
								lazy: () => import("./pages/date/DateView"),
							},
							// Filters
							{
								path: "/city/:cid/search",
								lazy: () => import("./pages/search/SearchView"),
							},
							{
								path: "/city/:cid/filters",
								lazy: async () => {
									const { FiltersView } = await import("./pages/filters");
									return { Component: FiltersView };
								},
							},
							{
								path: "/city/:cid/filters/city",
								lazy: async () => {
									const { FiltersCityView } = await import("./pages/filters");
									return { Component: FiltersCityView };
								},
							},
							{
								path: "/city/:cid/filters/genres",
								lazy: async () => {
									const { FiltersGenresView } = await import("./pages/filters");
									return { Component: FiltersGenresView };
								},
							},
							{
								path: "/city/:cid/filters/kind",
								lazy: async () => {
									const { FiltersKindView } = await import("./pages/filters");
									return { Component: FiltersKindView };
								},
							},
							{
								path: "/city/:cid/filters/venues",
								lazy: async () => {
									const { FiltersVenuesView } = await import("./pages/filters");
									return { Component: FiltersVenuesView };
								},
							},
							{
								path: "/city/:cid/filters/date",
								lazy: async () => {
									const { FiltersDateView } = await import("./pages/filters");
									return { Component: FiltersDateView };
								},
							},
							{
								id: "action",
								lazy: async () => {
									const { loader } = await import("./pages/action/ActionView");
									return { loader };
								},
								shouldRevalidate: () => false,
								children: [
									{
										path: "/city/:cid/:action",
										lazy: async () => {
											const { Component } = await import(
												"./pages/action/ActionView"
											);
											return { Component };
										},
									},
									{
										path: "/city/:cid/:action/reservation/:eventId",
										lazy: () => import("./pages/reservation/ReservationView"),
									},
								],
							},
						],
					},
					// Checkout page (create order)
					{
						path: "/checkout",
						lazy: () => import("./pages/checkout/CheckoutView"),
					},
					// Info pages
					{
						path: "/faq",
						lazy: () => import("./pages/faq/FAQView"),
					},
					{
						path: "/docs",
						lazy: () => import("./pages/docs/DocsView"),
					},
					// My orders page
					{
						path: "/orders",
						lazy: () => import("./pages/orders/OrdersView"),
					},
					{
						id: "ticket",
						lazy: async () => {
							const { loader } = await import("./pages/tickets/TicketView");
							return { loader };
						},
						shouldRevalidate: () => false,
						children: [
							// Ticket page
							{
								path: "/orders/:oid",
								lazy: async () => {
									const { Component } = await import(
										"./pages/tickets/TicketView"
									);
									return { Component };
								},
							},
							// Refund pages
							{
								path: "/orders/:oid/refund",
								lazy: () => import("./pages/refund/RefundView"),
							},
						],
					},
					// Success payment callback page
					{
						path: "/success/:oid/:uid",
						lazy: () => import("./pages/payment/SuccessPaymentView"),
					},
					// Failed payment callback page
					{
						path: "/fail/:oid/:uid",
						lazy: () => import("./pages/payment/FailedPaymentView"),
					},
					{
						path: "/refund-policy",
						lazy: () => import("./pages/refund-policy/RefundPolicyView"),
					},
				],
			},
		],
	},
]);

export const App: React.FC = () => {
	return <RouterProvider router={router} />;
};

import { useRouteError } from "react-router-dom";
import { Button } from "@alfalab/core-components/button";

import { APIError } from "@/lib/error";
import { Platform, detectPlatform } from "@/lib/utils/detectPlatform";

import { CriticalError } from "./CriticalError";

const MOBILE_HOME_URI =
	"alfabank://webFeature?type=recommendation&url=https%3A%2F%2Fweb.alfabank.ru%2Fpartner-offers%2Fafisha-transfer";

const WEB_HOME_URI = "http://web.alfabank.ru";

export const ErrorBoundary: React.FC = () => {
	const error = useRouteError() as Error;

	console.error(error);

	const isWeb = detectPlatform() === Platform.WEB;
	const homeUrl = isWeb ? WEB_HOME_URI : MOBILE_HOME_URI;

	if (error instanceof APIError) {
		if (isWeb && error.error === -1)
			return (
				<CriticalError
					title="Не получилось загрузить"
					advice="Вернитесь в Альфа-Онлайн и попробуйте ещё раз"
					action={
						<Button
							block
							size="m"
							view="primary"
							onClick={() => window.location.replace(homeUrl)}
						>
							Перейти в Альфа-Онлайн
						</Button>
					}
				/>
			);
	}

	return (
		<CriticalError
			advice={"На нашей стороне, но мы уже чиним"}
			action={
				<Button
					block
					size="m"
					view="primary"
					onClick={() => window.location.replace(homeUrl)}
				>
					Вернуться на главный экран
				</Button>
			}
		/>
	);
};

import { Typography } from "@alfalab/core-components/typography";
import cx from "classnames";

import styles from "./AgeLabel.module.css";

type AgeLabelProps = {
	age: string;
	className?: string;
};

export const AgeLabel: React.FC<AgeLabelProps> = ({ age, className }) => {
	return (
		<span className={cx(styles["container"], className)}>
			<Typography.Text
				view="secondary-medium"
				weight="medium"
				color="static-primary-light"
			>
				{age}
			</Typography.Text>
		</span>
	);
};

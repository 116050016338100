import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Typography } from "@alfalab/core-components/typography";

import "swiper/css";

import { Cashback } from "@/components/cashback/Cashback";
import { AgeLabel } from "@/components/age-label/AgeLabel";
import { formatString } from "@/lib/utils";
import type { SliderItem } from "@/lib/types";

import styles from "./Slider.module.css";

type VideoProps = React.DetailedHTMLProps<
	React.VideoHTMLAttributes<HTMLVideoElement>,
	HTMLVideoElement
> & {
	active: boolean;
};

const Video: React.FC<VideoProps> = ({ src, active, ...props }) => {
	const videoRef = useRef<HTMLVideoElement | null>(null);

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.muted = true;
			videoRef.current.defaultMuted = true;
			videoRef.current.playsInline = true;

			if (active) {
				videoRef.current.play();
			} else {
				videoRef.current.pause();
				videoRef.current.currentTime = 0;
			}
		}
	}, [active]);

	return (
		<video ref={videoRef} loop muted playsInline {...props}>
			<source src={src} type="video/mp4" />
		</video>
	);
};

type SliderProps = {
	data: Array<SliderItem>;
};

export const Slider: React.FC<SliderProps> = ({ data }) => {
	const navigate = useNavigate();
	const [activeIndex, setActiveIndex] = useState(0);

	if (data.length === 0) {
		return null;
	}

	return (
		<div className={styles["container"]}>
			<Swiper slidesPerView={1} loop>
				{data.map((slide, idx) => (
					<SwiperSlide
						className={styles["slide-container"]}
						key={slide.id}
						onClick={() =>
							(slide.pageUrl || slide.actionId) && slide.pageUrl
								? window.open(slide.pageUrl, "_self")
								: navigate(
										slide.eventId
											? `/city/${slide.cityId}/${slide.venueId}_${slide.actionId}/reservation/${slide.eventId}`
											: `/city/${slide.cityId}/${slide.venueId}_${slide.actionId}`,
									)
						}
					>
						<div className={styles["slide"]}>
							<div className={styles["slide-header"]}>
								{slide.video ? (
									<Video
										src={slide.video}
										poster={slide.picture}
										active={idx === activeIndex}
										className={styles["slide-cover"]}
									/>
								) : (
									<img
										src={slide.picture}
										alt={slide.title}
										className={styles["slide-cover"]}
									/>
								)}

								{slide.age && (
									<div className={styles["age"]}>
										<AgeLabel age={slide.age} />
									</div>
								)}

								{slide.cashback && (
									<div className={styles["cashback"]}>
										<Cashback value={`${slide.cashback}%`} />
									</div>
								)}
							</div>

							<div className={styles["content"]}>
								<div className={styles["content-left"]}>
									<Typography.Text
										weight="bold"
										className={styles["content-title"]}
									>
										{formatString(slide.title) || "title"}
									</Typography.Text>
									<Typography.Text view="primary-small">
										{slide.dates}
									</Typography.Text>
									<Typography.Text
										view="primary-small"
										color="secondary"
										className={styles["content-venue"]}
									>
										{formatString(slide.place) || "place"}
									</Typography.Text>
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}

				{/* <div className={styles["controls"]}>
					<Typography.Text color="static-primary-light">
						{activeIndex + 1} / {data.length}
					</Typography.Text>

					<div className={styles["controls-arrows"]}>
						<button
							type="button"
							className={cx(styles["control-arrow"], "swiper-button-prev")}
						>
							<ArrowLeftMIcon style={{ zIndex: 1 }} />
						</button>

						<button
							type="button"
							className={cx(styles["control-arrow"], "swiper-button-next")}
							style={{
								background: `conic-gradient(red ${timer / (7000 / 100)
									}%, transparent 0)`,
							}}
						>
							<ArrowRightMIcon style={{ zIndex: 1 }} />
						</button>
					</div>
				</div> */}
			</Swiper>
		</div>
	);
};

export const MAX_CITIES_HISTORY_ITEMS = 5;
export const MAX_PLACES_HISTORY_ITEMS = 5;
export const MAX_SEARCH_RESULTS = 50;
export const MAX_POPULAR_PLACES = 15;

export const ENV =
	(process.env.PUBLIC_DEPLOY_ENV as "E2E" | "test" | "prod") || "prod";

export const API_ENDPOINT = {
	E2E: "https://api.q-transport.ru",
	// test: "https://api-alfa-test.kassir.ru",
	test: "https://api.q-transport.ru",
	prod: "https://api-alfa.kassir.ru",
}[ENV];

import { useEffect } from "react";
import {
	type LoaderFunction,
	useLoaderData,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { Spinner } from "@alfalab/core-components/spinner";

import { apiService } from "@/lib/api";
import type { AlfaAuthResult } from "@/lib/types";

import styles from "./AuthView.module.css";

const filterNames = ["date", "kind", "venue", "menu"];

type AuthData = AlfaAuthResult;

export const AuthView: React.FC = () => {
	const data = useLoaderData() as AuthData;
	const navigate = useNavigate();
	const [queryParams] = useSearchParams();

	useEffect(() => {
		if (String(data.cityId) === "0") {
			navigate("/city", { replace: true });
		} else {
			localStorage.setItem("city", String(data.cityId));

			const itemId = queryParams.get("itemId");

			if (itemId) {
				const url = new URL(`${location.origin}${itemId}`);

				for (const name of queryParams.keys()) {
					if (filterNames.includes(name)) {
						const param = queryParams.get(name);
						if (param) {
							url.searchParams.set(name, param);
						}
					}
				}

				navigate(`${url.pathname}${url.search}`, { replace: true });
			} else {
				navigate(`/city/${data.cityId}`, { replace: true });
			}
		}
	}, [data]);

	return (
		<div className={styles["container"]}>
			<Spinner size="m" visible />
		</div>
	);
};

export const authLoader: LoaderFunction = ({ params }) => {
	return apiService.alfaAuth({ guid: String(params.guid) });
};

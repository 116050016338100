import { Space } from "@alfalab/core-components/space";
import { Typography } from "@alfalab/core-components/typography";
import { SuperEllipse } from "@alfalab/core-components/icon-view/super-ellipse";

import styles from "./EmptyState.module.css";

type EmptyStateProps = {
	title: string;
	message: string;
	icon: React.ReactNode;
	action?: React.ReactNode;
};

export const EmptyState: React.FC<EmptyStateProps> = ({
	title,
	icon,
	message,
	action,
}) => {
	return (
		<div className={styles["container"]}>
			<Space size="l" className={styles["content"]}>
				<SuperEllipse size={80}>{icon}</SuperEllipse>

				<Space align="center" size={"s"}>
					<Typography.TitleMobile tag="h2" view="small" weight="medium">
						{title}
					</Typography.TitleMobile>

					<Typography.Text view="primary-small">{message}</Typography.Text>
				</Space>
			</Space>

			{action}
		</div>
	);
};

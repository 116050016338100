import { useState, useMemo, useCallback } from "react";
import {
	useQueryParam,
	NumberParam,
	withDefault,
	DelimitedNumericArrayParam,
} from "use-query-params";
import { endOfDay, startOfDay } from "date-fns";

import { createContext } from "../utils/context";
import type {
	City,
	Venue,
	Kind,
	Action,
	GetAllActionsByCityResult,
} from "../types";
import { formatString } from "../utils/formatString";

type FiltersState = {
	kind: Kind | null;
	venues: Venue["venueId"][];
	genres: Kind["kindId"][];
	date: [Date, Date] | [Date] | null;
	search: string;
	popular: boolean;
	weekend: boolean;
	month: boolean;
	menu?: number | null;
};

type ActionsContext = {
	city: City & { where: string };
	kinds: Array<GetAllActionsByCityResult["kinds"][number]>;
	genres: Array<GetAllActionsByCityResult["genres"][number]>;
	venues: Array<GetAllActionsByCityResult["venues"][number]>;
	menus: Array<GetAllActionsByCityResult["menu"][number]>;
	actions: Array<
		Omit<Action, "venues"> & {
			venues: Array<{
				cityId: number;
				cityName: string;
				venueId: number;
				venueName: string;
				href: string;
			}>;
		}
	>;
	slider: Array<GetAllActionsByCityResult["slider"][number]>;
	selections: Array<GetAllActionsByCityResult["selections"][number]>;
	cities: Array<GetAllActionsByCityResult["cities"][number]>;
	filters: FiltersState;
	setFilters: React.Dispatch<React.SetStateAction<FiltersState>>;
	totalFiltersCount: number;
	totalResultsCount: number;
	resetFilters(): void;
	setMenu: (newValue: number | null) => void;
	newOrders: {
		orders: number;
		time: number;
	};
};

const [useActions, ActionsProvider] = createContext<ActionsContext>("Actions");

const initialFiltersState: FiltersState = {
	kind: null,
	venues: [],
	genres: [],
	date: null,
	search: "",
	popular: false,
	month: false,
	weekend: false,
};

type WithActionsProps = {
	data: GetAllActionsByCityResult;
	children: React.ReactNode;
};

const WithActions: React.FC<WithActionsProps> = ({ data, children }) => {
	const [filters, setFilters] = useState<FiltersState>(initialFiltersState);
	const [menu, setMenu] = useQueryParam(
		"menu",
		withDefault(NumberParam, null),
		{ removeDefaultsFromUrl: true, enableBatching: true },
	);
	const [venues, setVenues] = useQueryParam(
		"venue",
		withDefault(DelimitedNumericArrayParam, []),
		{ removeDefaultsFromUrl: true, enableBatching: true },
	);
	const [genres, setGenres] = useQueryParam(
		"kind",
		withDefault(DelimitedNumericArrayParam, []),
		{ removeDefaultsFromUrl: true, enableBatching: true },
	);
	const [date, setDate] = useQueryParam(
		"date",
		withDefault(DelimitedNumericArrayParam, null),
		{ removeDefaultsFromUrl: true, enableBatching: true },
	);

	const city = useMemo<City & { where: string }>(() => {
		return {
			cityId: data.cityId,
			cityName: data.cityName,
			where: data.where,
		};
	}, [data]);

	const filteredActions = useMemo(() => {
		return Object.values(data.actions)
			.filter((action) => {
				// venues filter
				if (
					venues.length > 0 &&
					!venues.some((venueId) => {
						return Object.values(action.venues)
							.map(({ venueId }) => venueId)
							.includes(Number(venueId));
					})
				) {
					return false;
				}

				// menu filter
				if (
					menu &&
					!action.menu
						.map(({ menuId }) => String(menuId))
						.includes(String(menu))
				) {
					return false;
				}

				// genres filter
				if (
					filters.genres.length > 0 &&
					!filters.genres.some((genreId) => {
						return Object.values(action.genres)
							.map(({ genreId }) => genreId)
							.includes(genreId);
					})
				) {
					return false;
				}

				// date filter
				if (date) {
					const [dateFrom, dateTo] = date;

					if (dateFrom && dateTo) {
						return (
							action.from * 1000 >= startOfDay(Number(dateFrom)).getTime() &&
							action.from * 1000 <= endOfDay(Number(dateTo)).getTime()
						);
					}

					return action.from * 1000 >= startOfDay(Number(dateFrom)).getTime();
				}

				return true;
			})
			.map((action) => ({
				...action,
				venues: Object.values(action.venues).filter(
					({ cityId }) => cityId === data.cityId,
				),
			}));
	}, [data, filters, venues, menu]);

	const totalFiltersCount = useMemo(() => {
		return (
			(filters.kind ? 1 : 0) +
			(filters.date ? 1 : 0) +
			(filters.popular ? 1 : 0) +
			(filters.weekend ? 1 : 0) +
			(filters.month ? 1 : 0) +
			venues.length +
			Object.values(filters.genres).filter((isChecked) => isChecked).length
		);
	}, [filters]);

	const resetFilters = useCallback(() => {
		setFilters(initialFiltersState);
	}, []);

	const ctxValue = useMemo<ActionsContext>(
		() => ({
			city,
			cities: data.cities,
			kinds: data.kinds,
			genres: data.genres,
			venues: data.venues.map(({ venueName, ...venue }) => ({
				...venue,
				venueName: formatString(venueName),
			})),
			slider: data.slider,
			menus: data.menu,
			selections: data.selections,
			filters: {
				...filters,
				venues: venues.map((id) => Number(id)) || [],
				genres: genres.map((id) => Number(id)) || [],
				date: date?.[0]
					? date[1]
						? [new Date(date[0]), new Date(date[1])]
						: [new Date(date[0])]
					: null,
				menu,
			},
			setFilters: (state) => {
				let newState: FiltersState;

				if (typeof state === "function") {
					newState = state(filters);
				} else {
					newState = state;
				}

				setFilters(newState);
				setVenues(newState.venues);
				setGenres(newState.genres);
				setDate(
					newState.date
						? newState.date[1]
							? [newState.date[0].getTime(), newState.date[1].getTime()]
							: [newState.date[0].getTime()]
						: null,
				);
			},
			resetFilters,
			totalFiltersCount,
			totalResultsCount: filteredActions.length,
			setMenu,
			actions: filteredActions,
			newOrders: data.new_orders,
		}),
		[
			city,
			filteredActions,
			filters,
			setFilters,
			resetFilters,
			totalFiltersCount,
			menu,
			setMenu,
			venues,
			setVenues,
			genres,
			setGenres,
			date,
			setDate,
			data,
		],
	);

	return <ActionsProvider value={ctxValue}>{children}</ActionsProvider>;
};

export { useActions, WithActions };

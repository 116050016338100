const TranslitMap: { [key: string]: string } = {
	а: "a",
	б: "b",
	в: "v",
	г: "g",
	д: "d",
	е: "e",
	ё: "yo",
	ж: "zh",
	з: "z",
	и: "i",
	й: "y",
	к: "k",
	л: "l",
	м: "m",
	н: "n",
	о: "o",
	п: "p",
	р: "r",
	с: "s",
	т: "t",
	у: "u",
	ф: "f",
	х: "kh",
	ц: "ts",
	ч: "ch",
	ш: "sh",
	щ: "shch",
	ы: "y",
	э: "e",
	ю: "yu",
	я: "ya",
	А: "A",
	Б: "B",
	В: "V",
	Г: "G",
	Д: "D",
	Е: "E",
	Ё: "Yo",
	Ж: "Zh",
	З: "Z",
	И: "I",
	Й: "Y",
	К: "K",
	Л: "L",
	М: "M",
	Н: "N",
	О: "O",
	П: "P",
	Р: "R",
	С: "S",
	Т: "T",
	У: "U",
	Ф: "F",
	Х: "Kh",
	Ц: "Ts",
	Ч: "Ch",
	Ш: "Sh",
	Щ: "Shch",
	Ы: "Y",
	Э: "E",
	Ю: "Yu",
	Я: "Ya",
};

export function transliterate(russianText: string): string {
	const transliterated = russianText
		.split("")
		.map((char) => TranslitMap[char] || char)
		.join("");

	return transliterated
		.split(/[^a-zA-Z0-9]/)
		.filter(Boolean)
		.map((word, index) => {
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		})
		.join("");
}

import { Fragment, useEffect, useMemo } from "react";
import type { LoaderFunction } from "react-router-dom";

import { DaySelector } from "@/components/day-selector/DaySelector";
import { useActions } from "@/lib/actions/ActionsContext";
import { trackScreenView } from "@/lib/metrica";
import { usePageSettings } from "@/lib/hooks";

import { Header } from "./header/Header";
import { ActionList } from "./action-list/ActionList";
import { Slider } from "./slider/Slider";
import { Selections } from "./selections/Selections";

import styles from "./CityView.module.css";

const PAGE_TITLE = "Афиша";

export const CityView: React.FC = () => {
	const { slider, selections, filters, totalFiltersCount } = useActions();

	usePageSettings({
		pageId: 1,
		pageTitle: PAGE_TITLE,
	});

	useEffect(() => {
		trackScreenView("MainBlock");
	}, []);

	const hasSlider = useMemo(() => {
		return totalFiltersCount === 0 && filters.menu === null;
	}, [totalFiltersCount, filters.menu]);

	useEffect(() => {
		window.scrollTo({
			behavior: "instant",
			top: 0,
		});
	}, [hasSlider]);

	return (
		<Fragment>
			<title>{PAGE_TITLE}</title>

			<Header />

			{hasSlider && <Slider data={slider} />}

			<div className={styles["container"]}>
				<DaySelector />
				{selections.length > 0 && <Selections data={selections} />}
				<ActionList />
			</div>
		</Fragment>
	);
};

export const cityViewLoader: LoaderFunction = ({ request }) => {
	const item = new URL(request.url).searchParams.get("item");

	return {
		item,
	};
};

import { useSearchParams } from "react-router-dom";

import { createContext } from "../utils/context";

export type Theme = "light" | "dark";

type AppContext = {
	appId: string;
	theme: Theme;
};

const [useAppContext, AppContextProvider] = createContext<AppContext>("App");

type AppContextProps = {
	children: React.ReactNode;
};

const WithApp: React.FC<AppContextProps> = ({ children }) => {
	const [searchParams] = useSearchParams();

	// AppID

	const appIdParam = searchParams.get("appId");
	const iosAppIdParam = searchParams.get("iosAppId");

	let appId = "alfabank";

	if (iosAppIdParam !== null) {
		appId = iosAppIdParam;
		localStorage.setItem("app_id", iosAppIdParam);
	} else if (appIdParam !== null) {
		appId = appIdParam;
		localStorage.setItem("app_id", appIdParam);
	} else {
		const cachedAppId = localStorage.getItem("app_id");

		if (cachedAppId) {
			appId = cachedAppId;
		}
	}

	// Theme

	let theme: Theme = "light";

	const alphaThemeParam = searchParams.get("theme");

	if (alphaThemeParam) {
		theme = alphaThemeParam as Theme;
		localStorage.setItem("theme", theme);
	} else {
		const storedTheme = localStorage.getItem("theme") as Theme;
		if (storedTheme) theme = storedTheme;
	}

	document.body.setAttribute("data-theme", theme);

	return (
		<AppContextProvider
			value={{
				appId,
				theme,
			}}
		>
			{theme === "dark" && <link href="/static/dark.css" rel="stylesheet" />}
			{children}
		</AppContextProvider>
	);
};

export { useAppContext, WithApp };
